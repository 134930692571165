import axios from 'axios'
import { getAccessToken } from '@/misc/token'
import { getEnvironments } from './environments'
import Message from 'element-ui/lib/message'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'
import { i18n } from '@/main'

let endpoints = {}

async function getReceptionEndpoint(env) {
  if (endpoints.hasOwnProperty(env)) {
    return endpoints[env]
  }
  const environments = await getEnvironments()
  for (const environment of environments) {
    endpoints[environment.name] = environment.console_endpoint
  }
  if (endpoints.hasOwnProperty(env)) {
    return endpoints[env]
  }

  const errMessage = i18n.t('ops.alert.reception.endpoint.notfound', { env: env }).toString()
  Message({
    type: 'error',
    message: errMessage,
    duration: 10000, // 10sec
  })
  throw new Error(errMessage)
}

export async function getClient(env) {
  const endpoint = await getReceptionEndpoint(env)
  const token = getAccessToken()
  const client = axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })

  client.interceptors.response.use((response) => response, handleReceptionError)

  return client
}

/**
 * HTTP 상태코드에 따른 에러 메시지 정보를 나타낸다.
 *
 * 키: HTTP 상태코드
 * 값: HTTP 상태코드에 해당하는 에러 메시지 옵션 객체
 */
const HTTP_STATUS_CODE_ERROR_MESSAGE = {
  [400]: { type: 'error', messageKey: 'ops.alert.http.response.400' },
  [401]: { type: 'error', messageKey: 'ops.alert.http.response.401' },
  [403]: { type: 'error', messageKey: 'ops.alert.http.response.403' },
  [404]: { type: 'warning', messageKey: 'ops.alert.http.response.404' },
}

/**
 * 커스텀 코드에 따른 에러 메시지 정보를 나타낸다.
 *
 * 키: 커스텀 코드
 * 값: 커스텀 코드에 해당하는 에러 메시지 옵션 객체
 *      - type <string>: Alert type
 *      - messageKey <string>: Alert message key
 *      - directManage <boolean>: axios interceptor에서 처리하지 않고 싶을때 true로 설정, 호출한 컴포넌트에서 직접 에러처리한다.
 */
const CUSTOM_CODE_ERROR_MESSAGE = {
  [40006]: { type: 'warning', messageKey: 'ops.alert.reception.response.invalidmember' },
  [40151]: { type: 'error', messageKey: 'ops.alert.reception.response.invalidauth' },
  [40213]: {
    type: 'warning',
    messageKey: 'ops.alert.reception.response.emailbouncenotfound',
  },
  [40214]: {
    type: 'warning',
    messageKey: 'ops.alert.reception.response.cannotrecoverplayer',
  },
  [40302]: { type: 'error', messageKey: 'ops.alert.reception.response.notunlinkable' },
  [40351]: { type: 'error', messageKey: 'ops.alert.reception.response.permissiondenied' },
  [47202]: { type: 'warning', messageKey: 'ops.alert.remoteconfig.response.alreadyexists' },
  [47203]: { type: 'warning', messageKey: 'ops.alert.remoteconfig.response.invalidvalue' },
  [47204]: { type: 'warning', messageKey: 'ops.alert.remoteconfig.response.cannotchangetype' },
  [47205]: { type: 'warning', messageKey: 'ops.alert.remoteconfig.response.notfound' },
  [47209]: {
    type: 'warning',
    messageKey: 'ops.alert.remoteconfig.response.cannotdelete',
    directManage: true,
  },
  [50101]: {
    type: 'error',
    messageKey: 'ops.alert.reception.response.dormantsystemerror',
  },
}

/**
 * HTTP 응답에 따라 에러 메시지를 띄우는 함수이다.
 * HTTP 상태코드와 커스텀 코드의 존재유무에 따라 띄우는 에러 메시지가 달라진다.
 *
 * HTTP 응답 본문내에 커스텀 코드가 존재하는 경우:
 *    핸들링하는 커스텀 코드인 경우: 커스텀 코드에 해당하는 에러 메시지를 띄운다.
 *    핸들링하지않는 커스텀 코드인 경우:
 *        핸들링하는 HTTP 상태코드인 경우: 커스텀 코드명과 HTTP 상태코드에 해당하는 에러메시지를 띄운다.
 *        핸들링하지않는 HTTP 상태코드인 경우: 커스텀 코드명과 기본 에러 메시지를 띄운다.
 *
 * HTTP 응답 본문내에 커스텀 코드가 존재하지 않는 경우:
 *    핸들링하는 HTTP 상태코드인 경우: HTTP 상태코드명과 HTTP 상태코드에 해당하는 에러 메시지를 띄운다.
 *    핸들링하지않는 HTTP 상태코드인 경우: HTTP 상태코드명과 기본 에러 메시지를 띄운다.
 *
 * @param error Axios 에러 객체
 * @returns {Promise<never>}
 */
export function handleReceptionError(error) {
  // 리셉션 서버와 통신 자체가 불가능한 경우
  if (!error.response) {
    Message({
      message: i18n.t('ops.alert.reception.response.connectionerror'),
      type: 'error',
      duration: 2000,
    })
  }
  const httpStatusCode = error.response.status
  const customCode = error.response.data && error.response.data.code
  const httpStatusCodeMsg = HTTP_STATUS_CODE_ERROR_MESSAGE[httpStatusCode]
  const customCodeMsg = CUSTOM_CODE_ERROR_MESSAGE[customCode]

  // 에러 핸들링을 axios interceptor에서 처리하지 않는 경우
  if (customCodeMsg && customCodeMsg.directManage) {
    return Promise.reject(error)
  }

  // 커스텀 코드와 HTTP 상태코드의 값에 따른 기본값들을 정의한다.
  const displayMsg = httpStatusCodeMsg
    ? i18n.t(httpStatusCodeMsg.messageKey)
    : i18n.t('ops.alert.http.response.error')
  const displayCode = customCode || httpStatusCode
  Message({
    message: i18n.t((customCodeMsg || {}).messageKey) || `[${displayCode}] ${displayMsg}`,
    type: (customCodeMsg || {}).type || (httpStatusCodeMsg || {}).type || 'error',
    duration: 2000,
  })
  return Promise.reject(error)
}

export async function getDevPlayUser(env, gameCode, queryType, queryId) {
  const client = await getClient(env)
  const resp = (await client.get(`/v1/games/${gameCode}/player/${queryType}/${queryId}`)).data
  return camelcaseKeysDeep(resp)
}

export async function getDormantUser(env, gameCode, queryType, queryId) {
  const client = await getClient(env)
  const resp = (await client.get(`/v1/games/${gameCode}/dormant/${queryType}/${queryId}`)).data
  return camelcaseKeysDeep(resp)
}

export async function deleteGamePlayer(env, gameCode, mid) {
  const client = await getClient(env)
  return (await client.delete(`/v1/games/${gameCode}/player/mid/${mid}`)).data
}

export async function unlinkIDP(env, gameCode, email, loginType) {
  const client = await getClient(env)
  return (await client.post(`/v1/games/${gameCode}/member/${email}/unlink/${loginType}`, {})).data
}

export async function revokeRefreshToken(env, gameCode, mid) {
  const client = await getClient(env)
  const resp = await client.delete(`v1/games/${gameCode}/player/mid/${mid}/refreshtoken`)
  return resp.data
}

export async function getTranslation(env, gameCode, key) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/translation/${key}`)
  return camelcaseKeysDeep(resp.data)
}

export async function postGeneratePromotionImageUploadUrl(env, gameCode, filename) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion/image-upload-url`, {
    filename,
  })
  return camelcaseKeysDeep(resp.data)
}

export async function uploadToS3(url, file) {
  const res = await axios.create().put(url, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-amz-acl': 'public-read',
    },
  })
  return camelcaseKeysDeep(res.data)
}

export async function getPromotions(env, gameCode) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/promotion`)
  return camelcaseKeysDeep(resp.data)
}

export async function getPromotion(env, gameCode, promotionId) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/promotion/${promotionId}`)
  return camelcaseKeysDeep(resp.data)
}

export async function postPromotion(env, gameCode, reqBody) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion`, decamelizeKeysDeep(reqBody))
  return camelcaseKeysDeep(resp.data)
}

export async function putPromotion(env, gameCode, promotionId, reqBody) {
  const client = await getClient(env)
  const resp = await client.put(
    `/v1/games/${gameCode}/promotion/${promotionId}`,
    decamelizeKeysDeep(reqBody),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function previewPromotion(env, gameCode, reqBody) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion/preview`, reqBody)
  return resp.data
}

export async function deleteMembers(env, gameCode, pattern) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/qa/member/${pattern}/delete`)
}

export async function getRefunds(env, gameCode, searchOptions, pageOptions) {
  const client = await getClient(env)
  const endpoint = `v1/games/${gameCode}/refunds`
  const makeQueryParams = function (opt) {
    const decamelized = decamelizeKeysDeep(opt)
    return Object.entries(decamelized)
      .map((d) => `${d[0]}=${d[1]}`)
      .join('&')
  }
  const queryParams = makeQueryParams(searchOptions) + '&' + makeQueryParams(pageOptions)
  const resp = (await client.get(`${endpoint}?${queryParams}`)).data
  return camelcaseKeysDeep(resp)
}

export async function postSubscriptionList(env, gameCode, mid) {
  const client = await getClient(env)
  const resp = await client.post(`v1/games/${gameCode}/subscriptions/list`, { mid: mid })

  return camelcaseKeysDeep(resp)
}

export async function postSubscriptionOrders(env, gameCode, mid, subscriptionId) {
  const client = await getClient(env)
  const resp = await client.post(`v1/games/${gameCode}/subscriptions/orders`, {
    mid: mid,
    subscription_id: subscriptionId,
  })

  return camelcaseKeysDeep(resp)
}

export async function recoverGuest(env, gameCode, guestMid, email) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/player/mid/${guestMid}/recovery`, { email })
}

export async function recoverPlayer(env, gameCode, email, mid) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/player/mid/${mid}/recovery-player`, {
    email,
  })
}

export async function resetPassword(env, gameCode, email) {
  const client = await getClient(env)
  const { data } = await client.post(`/v1/games/${gameCode}/member/${email}/reset_password`)
  return camelcaseKeysDeep(data)
}

export async function changeEmail(env, gameCode, email, wantedEmail) {
  const client = await getClient(env)
  await client.post(
    `/v1/games/${gameCode}/member/${email}/change_email`,
    decamelizeKeysDeep({ email, wantedEmail }),
  )
}

export async function getEmailBounce(env, gameCode, email) {
  const client = await getClient(env)
  const { data } = await client.get(`/v1/games/${gameCode}/member/${email}/bounce`)
  return camelcaseKeysDeep(data)
}

export async function deleteEmailBounce(env, gameCode, email) {
  const client = await getClient(env)
  await client.delete(`/v1/games/${gameCode}/member/${email}/bounce`)
}

export async function getMaintenanceExtra(env, gameCode, key) {
  const client = await getClient(env)
  const { data } = await client.get(`/v1/games/${gameCode}/maintenance/extra/${key}`)
  return camelcaseKeysDeep(data)
}
